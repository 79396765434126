.imageUploader {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	flex-direction: column;
	border-radius: 2rem;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.123);
	gap: 2rem;
}

.imageContainer {
	border-radius: 1rem;
	width: 100%;
	height: 400px;
	border: 2px dotted var(--primary-color);
	overflow: hidden;
	padding: 0.3rem;
}

.imageContainer img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.imageUploader input {
	display: none;
}

.imageUploader label {
	padding: 0.8rem;
	font-family: inherit;
	font-size: 1rem;
	border: none;
	border-radius: 0.25rem;
	background-color: var(--primary-color);
	margin-top: 0.5rem;
	color: white;
	cursor: pointer;
}
