/* form.css */

.order-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-back,
.btn-submit {
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  background-color: #4caf50;
  border: none;
  cursor: pointer;
}

.btn-back {
  background-color: #808080;
}

.btn-submit {
  background-color: #1e90ff;
}

.radio-group {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.radio-card {
  flex: 1;
}

.card-wrapper {
  padding: 20px;
  width: 290px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 100px;
}

.card-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 100%;
  position: relative;
  top: -20px;
}

.card-label:hover {
  background-color: #f2f2f2;
}

.card-image {
  width: 120px;
  height: 80px;
  margin-bottom: 10px;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.card-text {
  font-size: 16px;
}

.selected {
  border-color: #0080ff;
}
.unavailable-text {
  font-size: 14px;
  color: #999;
  margin-top: 5px;
  position: absolute;
  bottom: 0;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.btn-back,
.btn-submit {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.btn-back {
  background-color: #ccc;
}

.btn-submit {
  background-color: #0080ff;
  color: #fff;
}

.container {
  display: flex;
  margin-top: 40px;
}

.order-summary {
  flex-basis: calc(50% - 10px);
  margin-bottom: 20px;
}

.seller-details {
  flex-basis: calc(25% - 10px);
  margin-bottom: 20px;
}

.buyer-details {
  flex-basis: calc(25% - 10px);
  margin-bottom: 20px;
}

.details-card {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.summary-table {
  width: 100%;
  max-width: 500px; /* Adjust the maximum width as needed */
  margin: 0 auto;
}

.summary-table th,
.summary-table td {
  text-align: left;
  padding: 10px;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-group button {
  margin-right: 10px;
}

/* ----------------------------------------
 */
.receipt-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Add this line to vertically center the content */
  width: 300px;
  padding: 20px;
  border: 2px solid #000;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.receipt-item {
  text-align: center; /* Add this line to center-align the text within the receipt item */
  margin-bottom: 15px;
}

.key {
  font-weight: 500;
  margin-bottom: 5px;
}

.value {
  margin-bottom: 5px;
}

h2 {
  text-align: center; /* Add this line to center-align the h2 heading */
}
