.authPage {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 100%;
	background-color: green;
}

.authPage__left {
	background-color: white;
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.authPage__right {
	background-color: #f1f2f7;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}

.circle {
	width: 600px;
	aspect-ratio: 1;
	border-radius: 50%;
	background-color: var(--primary-color);
}

.backdrop {
	position: absolute;
	top: 50%;
	height: 50%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.144);
	backdrop-filter: blur(1.75rem);
	border-top: 1px solid rgba(255, 255, 255, 0.432);
	z-index: 3;
}

.fruits {
	position: absolute;
	inset: 0;
	display: flex;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(-2rem);
	transition: transform 0.5s ease-in-out, opacity 0.3s ease-in-out;
}

.fruits img {
	position: absolute;
	width: 300px;
	aspect-ratio: 1;
	object-fit: contain;
	transform: scale(1.5);
}

.fruits:nth-of-type(1) img {
	transform: scale(1.2);
}

.fruits img:nth-of-type(1) {
	transform: translateX(-50%) translateY(2rem);
	z-index: 3;
}

.fruits img:nth-of-type(3) {
	transform: translateX(50%) translateY(2rem);
	z-index: 3;
}

.login .fruits:nth-of-type(2) {
	transform: rotate(180deg) translateY(-2rem);
	opacity: 0;
}

.register .fruits:nth-of-type(1) {
	transform: rotate(-180deg) translateY(-2rem);
	opacity: 0;
}

.form {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 1.75rem;
	width: 100%;
	align-content: flex-start;
}

.loginForm {
	max-width: 30rem;
}

.loginForm > .title {
	text-align: center;
	grid-column: span 12;
}
.loginForm > .subtitle {
	transform: translateY(-15px);
	grid-column: span 12;
	text-align: center;
}

.loginForm__footer {
	text-align: center;
}

.loginForm__footer button {
	border: none;
	outline: none;
	background: none;
	font-size: 1rem;
	font-family: inherit;
	color: var(--primary-color);
	font-weight: 500;
	cursor: pointer;
}
