.productCard {
  display: grid;
  grid-template-rows: 300px auto;
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  /* overflow: hidden; */
  border-color: #cb8c3f;
  background-color: #f5f5dc;
  border-width: 1px;
}

.product__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product__image a {
  height: 100%;
}

.product__details {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.product__details form {
  grid-column: span 2;
}

.product__details .data {
  display: flex;
  gap: 0.4rem;
  flex-direction: column;
}

.product__details .data .title {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--primary-color);
}

.product__details .data .stock {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--primary-color);
}

.price {
  text-align: right;
  font-size: 2rem;
  font-weight: 500;
  color: #8b4513;
}

.productCards {
  background-color: #d1e2b8;
  display: flex;
  flex-direction: row;
}

.burger {
  position: relative;
  left: -25px;
}

.products {
  position: relative;

  padding-bottom: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 90%;
  /* padding-right: 15px; */
  /* margin-left: 30px; */
}

.input {
  background-color: #a5c989;
  width: min-content;
  padding: 0;
  position: relative;
  left: -40px;
}
.input * {
  background-color: #a5c989;
}

.pagination-wrapper {
  position: absolute; /* set position to absolute */
  bottom: 0; /* set to the bottom of the parent container */
  left: 0; /* align to the left edge of the parent container */
  right: 0; /* align to the right edge of the parent container */
  height: 60px; /* set the height of the pagination wrapper */
  display: flex; /* add display flex to align children */
  justify-content: center; /* center the children horizontally */
  align-items: center; /* center the children vertically */
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
}
